import mainStore from "../../store/main";
import { Link } from "react-router-dom";

const SectionHeader = () => {
  const { setDrawer } = mainStore();

  return (
    <>
      <div>
        <div className="section_header">
          <div
            data-animation="default"
            data-collapse="medium"
            data-duration="0"
            data-easing="linear"
            data-easing2="linear"
            role="banner"
            className="navbar-5 w-nav"
          >
            <div className="nav_wrapper">
              <div className="nav_menu-wrapper">
                <nav role="navigation" className="nav_menu w-nav-menu">
                  <Link to="/" className="nav_link w-nav-link">
                    Home
                  </Link>
                  <Link to="/contact-us" className="nav_link w-nav-link">
                    Contact
                  </Link>
                  <Link to="/shipping-policy" className="nav_link w-nav-link">
                    Shipping &amp; Returns
                  </Link>
                </nav>
                {/* <div className="nav_burger-menu">
                  <img
                    loading="lazy"
                    src="images/burger-menu-icon.svg"
                    alt=""
                    className="nav_burger-icon"
                  />
                </div> */}
              </div>
              <a href="#" className="logo_block w-inline-block">
                <img
                  loading="lazy"
                  src="images/Felo_Logo_White.svg"
                  alt=""
                  className="nav_logo-image"
                />
              </a>
              <a
                data-target="drawer"
                href="#"
                className="nav_cart-block w-inline-block"
                onClick={() => setDrawer(true)}
              >
                <img
                  loading="lazy"
                  src="images/cart-icon.svg"
                  alt=""
                  className="nav_cart-image"
                />
              </a>
              <div className="menu-btn_mobile w-nav-button">
                <div className="mob-burger-icon w-icon-nav-menu"></div>
              </div>
            </div>
          </div>
          <div className="notify_wrapper">
            <div className="notify_block-one">
              <div className="notify_offer-text">You Unlocked 60% OFF!</div>
            </div>
            <div className="notify_block-two">
              <div className="notify_offer-sub-text">
                You are 1 Unit Away from a <strong>FREE PILLOW!</strong>
              </div>
            </div>
            <div className="progress-bar_wrapper">
              <div className="pro-bar_components-inner for-notify">
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-one is-active"></div>
                  <div className="dk-progress-bar is-active"></div>
                </div>
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-two is-active"></div>
                  <div className="dk-progress-bar is-active"></div>
                </div>
                <div className="pro-bar_inner-icon-block for-desk">
                  <div className="notify_progress-circle-three"></div>
                  <div className="dk-progress-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="section_hero for-desktop">
          <div className="w-layout-blockcontainer container-medium on-hero w-container">
            <h1 className="heading-style-h1 hero-heading">
              Sleep Better, Wake <br />
              Up Pain Free
            </h1>
            <div className="tp-new-block is-mod is-center">
              <div className="div-block-883">
                <img
                  src="images/Trustpilot_Stars.svg"
                  loading="lazy"
                  alt=""
                  className="yp-img"
                />
              </div>
              <div className="div-block-884">
                <div className="tt-rat-txt-2 is-up">
                  Excellent 4.6 | 10,563 Reviews
                </div>
              </div>
            </div>
            <div className="new-badges-block">
              <img src="images/badges-long_v02.png" loading="lazy" alt="" />
            </div>
            <div className="hero_btn-wrapper">
              <a
                data-target="drawer"
                href="#"
                className="hero_cta-btn w-inline-block"
                onClick={() => setDrawer(true)}
              >
                <div className="hero_cta-btn-text">Redeem 62% OFF!</div>
              </a>
              <div className="other_info-wrapper">
                <a
                  href="shipping-policy.html"
                  target="_blank"
                  className="details_link"
                >
                  Shipping Details
                </a>
                <div className="pbb-divider"></div>
                <a
                  href="return-refund-policy.html"
                  target="_blank"
                  className="details_link"
                >
                  Return &amp; Refund Policy
                </a>
              </div>
            </div>
          </div>
        </section>
        <a
          data-target="drawer"
          href="#"
          class="pro-prog_inner-body w-inline-block default-bg"
          onClick={() => setDrawer(true)}
        >
          <div class="pro-bar_components">
            <div class="pro-bar_heading">
              <div class="pro-bar_heading-text">
                <strong>EXCLUSIVE SALE! </strong>Free Gifts With Your First
                Order
              </div>
            </div>
            <div class="gift_wrapper-header new-one">
              <div class="cart-head_gift-blocks">
                <div data-gift="container" class="gift-blocks new-look">
                  <div data-gift="1" class="gift_card is-active">
                    <div class="free_offer-badge">
                      <div class="free_offer-text">62% OFF</div>
                    </div>
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/fireworks.svg"
                      class="gift_image"
                      height={40}
                    />
                    <div data-bundle-gift="1" class="gift-overlay first"></div>
                    <div class="gift-locker first">
                      <img
                        src="images/lock-new.svg"
                        loading="lazy"
                        alt=""
                        class="gift-lockings"
                      />
                    </div>
                    <div
                      data-bundle-gift="2"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div data-gift="2" class="gift_card is-active">
                    <div class="free_offer-badge">
                      <div class="free_offer-text">
                        FREE <span class="text-span-171">$8.99</span>
                      </div>
                    </div>
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/shipping_truck.svg"
                      class="gift_image"
                      height={40}
                    />
                    <div data-bundle-gift="2" class="gift-overlay second"></div>
                    <div class="gift-locker second">
                      <img
                        src="images/lock-new.svg"
                        loading="lazy"
                        alt=""
                        class="gift-lockings"
                      />
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  {/* <div data-gift="3" class="gift_card">
                    <div class="free_offer-badge">
                      <div class="free_offer-text">
                        FREE <span class="text-span-172">$19.99</span>
                      </div>
                    </div>
                    <img
                      id="gift1image"
                      loading="eager"
                      alt=""
                      src="images/Small-03.svg"
                      class="gift_image"
                    />
                    <div data-bundle-gift="3" class="gift-overlay third"></div>
                    <div class="gift-locker third">
                      <img
                        src="images/lock-new.svg"
                        loading="lazy"
                        alt=""
                        class="gift-lockings"
                      />
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div> */}
                  <div data-gift="4" class="gift_card">
                    <div class="free_offer-badge">
                      <div class="free_offer-text">
                        FREE <span class="text-span-173">$79.99</span>
                      </div>
                    </div>
                    <img
                      sizes="(max-width: 479px) 36vw, 100vw"
                      srcset="images/02-p-500.png 500w, images/02.png 550w"
                      alt=""
                      src="images/02.png"
                      loading="eager"
                      id="gift1image"
                      class="gift_image"
                      height={40}
                    />
                    <div data-bundle-gift="4" class="gift-overlay forth"></div>
                    <div class="gift-locker forth">
                      <img
                        src="images/lock-new.svg"
                        loading="lazy"
                        alt=""
                        class="gift-lockings"
                      />
                    </div>
                    <div
                      data-bundle-gift="3"
                      class="gift-checkmark is-active w-embed"
                    >
                      <svg
                        width="420"
                        height="420"
                        viewbox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM11.003 16L18.073 8.929L16.659 7.515L11.003 13.172L8.174 10.343L6.76 11.757L11.003 16Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div data-gift="titles" class="gift-titles">
                  <div data-gift="1" class="gift_title is-active">
                    <div class="gift-title_text">Labor Day Sale</div>
                  </div>
                  <div data-gift="2" class="gift_title is-active">
                    <div class="gift-title_text">Free Shipping</div>
                  </div>
                  {/* <div data-gift="3" class="gift_title">
                    <div class="gift-title_text">Mystery Gift</div>
                  </div> */}
                  <div data-gift="4" class="gift_title">
                    <div class="gift-title_text">Free Pillow</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default SectionHeader;
